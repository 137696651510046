import React from 'react';
import './Footer.scss';

export default function Footer() {
    return (
        <footer>
            <ul className="icons">
                <li id='glow'>
                    <a href={'mailto:mb@chariot.ventures'}>
                        <i className='envelope'>✉</i>
                        <span id='consult-link'> need a consultation?</span>
                    </a>
                </li>
            </ul>
            <ul className="copyright">
                <li>&copy; chariot ventures</li>
            </ul>
        </footer>
    );
}
