import React from 'react';
import './Splash.scss';

const Splash = () => {
    return (
        <header className='splash'>
            <h1>chariot ventures 🧪</h1>
            <div className='splash-text'>
                <p>Your work tools should be as invisible, intuitive, and simple to use as your middle toe and ring finger.&nbsp;What might appear as a resource or business process problem is likely just a technological deficiency <span id='word-hidden'>hidden</span>&nbsp;in plain sight.&nbsp;Businesses, like cars, need regular inspections (and you're probably overdue for an oil change).</p>
                <ul>
                    <li>Forget about your software and consistently get things done quickly and efficiently</li>
                    <li>Streamline and maximize your work without hiring additional employees</li>
                    <li>Automate your repetitive tasks and do more of what you want</li>
                </ul>
                <p>Leave us your e-mail address and you'll be the first to know when we're up and running. 🏃</p>
            </div>
        </header>
    );
}

export default Splash