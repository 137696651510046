import React, { Component } from 'react';
import './EmailForm.scss';

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export class EmailForm extends Component {
    constructor() {
        super();
        this.state = { message: '' };
        this.onSubmit = this.onSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = e => this.setState({ [e.target.name]: e.target.value, message: '' });

    onSubmit(e) {
        e.preventDefault();
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "notify-form-name", ...this.state })
        })
            .then(() => {
                this.setState({ message: 'Thank you, stay tuned!' });
            })
            .catch(error => alert(error));
    }

    render() {
        const { message } = this.state;
        return (
            <div className='email-form'>
                <form id="notify-form-id" name="notify-form-name" onSubmit={this.onSubmit} method="POST" data-netlify="true">
                    <input type="hidden" name="form-name" value="notify-form-name" />
                    <input type="email" name="email" id="email" placeholder="Email Address" onChange={this.handleChange} />
                    <input type="submit" value="Sign Up" id='submit-button' />
                    <span className={`${message ? 'visible success' : ''} message`}>
                        {message}
                    </span>
                </form>
            </div>
        );
    }
}

export default EmailForm;
