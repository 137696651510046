import './App.scss';
import Splash from './Splash';
import Footer from './Footer';
import EmailForm from './EmailForm';
import image1 from './vloc-6020405.jpg'

function App() {
  return (
    <div className="App">
      <img className='background-tint' src={image1} alt='background'/>
      <Splash />
      <EmailForm />
      <Footer />
    </div>
  );
}

export default App;